import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import get from "lodash.get";

import Container from "../styles/Container";

const Wrapper = styled.div`
  padding-top: 37px;
  border-top: 1px solid ${(p) => p.theme.lightGrey};
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 50px;
  }
`;

const Heading = styled.h1`
  font-size: 20px;
  line-height: 30px;
  font-weight: 900;
  margin-bottom: 20px;
  text-transform: uppercase;
  text-align: center;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 26px;
    line-height: 38px;
  }
`;

const Subheading = styled.p`
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 36px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 45px;
  }
`;

const SeriesContainer = styled.div`
  @media (min-width: 600px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const Series = styled.div`
  margin-bottom: 37px;
  @media (min-width: 600px) {
    width: calc(50% - 20px);
    :nth-child(2n + 1) {
      margin-right: 20px;
    }
    :nth-child(2n) {
      margin-left: 20px;
    }
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 50px;
    width: calc((100% - 64px) / 3);
    :nth-child(n) {
      margin-left: 0px;
      margin-right: 0px;
    }
    :not(:nth-child(3n)) {
      margin-right: 32px;
    }
  }
`;

const Image = styled.img`
  display: 100%;
  margin-bottom: 25px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 20px;
  }
`;

const Title = styled.h1`
  font-family: "effra", sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 8px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    text-align: left;
    font-size: 20px;
    line-height: 26px;
  }
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    text-align: left;
    font-size: 16px;
    line-height: 30px;
  }
`;

const LearnMore = styled(Link)`
  display: block;
  font-size: 14px;
  line-height: 26px;
  font-weight: 700;
  text-align: center;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: inline-block;
    text-align: left;
    font-size: 16px;
    line-height: 30px;
  }
  :hover {
    text-decoration: underline;
  }
`;

const OriginalSeriesList = ({ heading, subheading, items }) => {
  return (
    <Container>
      <Wrapper>
        {heading && <Heading>{heading}</Heading>}
        {subheading && <Subheading>{subheading}</Subheading>}
        {items && (
          <SeriesContainer>
            {items.map((item, index) => {
              return (
                <Series key={index}>
                  {item.series.document &&
                    item.series.document.data.preview_image && (
                      <Link to={`/series/${item.series.document.uid}`}>
                        <Image
                          src={item.series.document.data.preview_image.url}
                          alt={item.series.document.data.preview_image.alt}
                        />
                      </Link>
                    )}
                  {item.series.document && item.series.document.data.title && (
                    <Title>{item.series.document.data.title}</Title>
                  )}
                  {item.series.document &&
                    item.series.document.data.short_description && (
                      <>
                        <Description>
                          {item.series.document.data.short_description}
                        </Description>
                        {item.series.document.uid && (
                          <LearnMore to={`/series/${item.series.document.uid}`}>
                            Learn More
                          </LearnMore>
                        )}
                      </>
                    )}
                </Series>
              );
            })}
          </SeriesContainer>
        )}
      </Wrapper>
    </Container>
  );
};

export default OriginalSeriesList;
