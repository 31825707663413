import React from "react";
import styled from "styled-components";
import get from "lodash.get";

import Container from "../styles/Container";

const OuterContainer = styled.div`
  background-color: ${(p) => p.theme.lightBlue};
`;
const Wrapper = styled.div`
  text-align: center;
`;

const Heading = styled.h1`
  margin-bottom: 20px;
  padding-top: 37px;
  font-size: 22px;
  line-height: 30px;
  font-weight: 900;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 50px;
    font-size: 24px;
    line-height: 38px;
  }
`;

const Subheading = styled.p`
  font-size: 16px;
  line-height: 26px;
`;

const IconsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 37px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    justify-content: space-around;
    padding-top: 50px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 37px;
  width: 100%;
  @media (min-width: 450px) {
    width: calc(50% - 8px);
    :nth-child(2n + 1) {
      margin-right: 8px;
    }
    :nth-child(2n) {
      margin-left: 8px;
    }
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: 25%;
    max-width: 200px;
    margin-bottom: 50px;
    :nth-child(2n + 1) {
      margin-right: 8px;
      margin-left: 8px;
    }
    :nth-child(2n) {
      margin-right: 8px;
      margin-left: 8px;
    }
  }
`;

const Icon = styled.img`
  max-height: 67px;
  max-width: 100px;
  margin-bottom: 18px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 24px;
  }
`;
const IconDescription = styled.p`
  font-size: 18px;
  line-height: 20px;
  margin-bottom: auto;
  max-width: 200px;
  ${(p) => !p.hasHeading && "font-weight: 900;"}
`;

const IconHeading = styled.h1`
  font-size: 20px;
  line-height: 26px;
  font-weight: 900;
`;

const IconBox = ({ heading, subheading, icons }) => {
  return (
    <OuterContainer>
      <Container>
        <Wrapper>
          {heading && <Heading>{heading}</Heading>}
          {subheading && <Subheading>{subheading}</Subheading>}
          {icons && (
            <IconsContainer>
              {icons.map(({ icon, icon_description, icon_heading }, index) => {
                const iconUrl = get(icon, "url");
                const iconAlt = get(icon, "alt");
                return (
                  <IconWrapper key={index}>
                    {iconUrl && <Icon src={iconUrl} alt={iconAlt} />}
                    {(icon_heading || icon_description) && (
                      <div>
                        {icon_heading && (
                          <IconHeading>{icon_heading}</IconHeading>
                        )}
                        {icon_description && (
                          <IconDescription hasHeading={icon_heading}>
                            {icon_description}
                          </IconDescription>
                        )}
                      </div>
                    )}
                  </IconWrapper>
                );
              })}
            </IconsContainer>
          )}
        </Wrapper>
      </Container>
    </OuterContainer>
  );
};

export default IconBox;
