import React from "react";
import { graphql } from "gatsby";

import NetworksHero from "../../components/NetworksHero";
import IconBox from "../../components/IconBox";
import ViewNext from "../../components/ViewNext";
import OriginalSeriesList from "../../components/OriginalSeriesList";
import ImageList from "../../components/ImageList";
import Divisions from "../../components/Divisions";
import Carousel from "../../components/Carousel";
import HelmetInfo from "../../components/HelmetInfo";

const Distribution = ({ data }) => {
  const body = data.body;
  const title = data.title;

  const productions = data.productions;

  let nextProductionTitle = undefined;
  let nextProductionUID = undefined;

  if (Array.isArray(productions)) {
    const index = productions.findIndex((production) => {
      const productionTitle = production.production.title;
      return productionTitle === title;
    });
    if (index != -1) {
      const length = productions.length;
      if (length - 1 > index) {
        const nextProduction = productions[index + 1];
        nextProductionTitle = nextProduction.production.title;
        nextProductionUID = nextProduction.production.uid;
      }
    }
  }

  const nextSeriesTitle = data.prismicDistribution.data.body[0].items[0].title;
  const nextSeriesUID = data.prismicDistribution.data.body[0].items[0].uid;

  return (
    <>
      <HelmetInfo page_data={data.prismicDistribution.data} />
      <NetworksHero
        image={data.prismicDistribution.data.hero_image}
        logo={
          data.prismicDistribution.data.show_logo_on_production_page === "no"
            ? undefined
            : data.prismicDistribution.data.logo
        }
        text={data.prismicDistribution.data.description}
        buttons={data.prismicDistribution.data.buttons}
        facebook_link={data.prismicDistribution.data.facebook_link}
        instagram_link={data.prismicDistribution.data.instagram_link}
        twitter_link={data.prismicDistribution.data.twitter_link}
        youtube_link={data.prismicDistribution.data.youtube_link}
        linkedin_link={data.prismicDistribution.data.linkedin_link}
      />
      {data.prismicDistribution.data.body &&
        data.prismicDistribution.data.body.map((slice, index) => {
          if (slice.__typename === "PrismicDistributionDataBodyInfobox") {
            return (
              <IconBox
                key={index}
                heading={slice.primary.heading}
                subheading={slice.primary.subheading}
                icons={slice.items}
              />
            );
          } else if (
            slice.__typename === "PrismicDistributionDataBodyOriginalSeriesList"
          ) {
            return (
              <OriginalSeriesList
                key={index}
                heading={slice.primary.heading}
                subheading={slice.primary.subheading}
                items={slice.items}
              />
            );
          } else if (
            slice.__typename === "PrismicDistributionDataBodySeriesLogosList"
          ) {
            return <ImageList key={index} items={slice.items} />;
          } else if (
            slice.__typename === "PrismicDistributionDataBodyImagelist"
          ) {
            return (
              <ImageList
                key={index}
                heading={slice.primary.heading}
                items={slice.items}
              />
            );
          } else if (
            slice.__typename === "PrismicDistributionDataBodyDivisions"
          ) {
            return (
              <Divisions
                key={index}
                uid={data.prismicDistribution.uid}
                heading={slice.primary.divisions_heading}
                items={slice.items}
              />
            );
          } else if (
            slice.__typename === "PrismicDistributionDataBodyCarousel"
          ) {
            return (
              <Carousel
                key={index}
                heading={slice.primary.heading}
                items={slice.items}
              />
            );
          }
          return null;
        })}
      {title === "Original Programming" &&
      nextSeriesTitle &&
      nextProductionUID ? (
        <ViewNext
          link={`/series/${nextSeriesUID}`}
          linkText={nextSeriesTitle}
          borderTop
        />
      ) : nextProductionTitle && nextProductionUID ? (
        <ViewNext
          link={`/productions/${nextProductionUID}`}
          linkText={nextProductionTitle}
          borderTop
        />
      ) : (
        <ViewNext link={`/news`} linkText="News" borderTop />
      )}
    </>
  );
};

export default Distribution;

export const query = graphql`
  query ($uid: String!) {
    prismicDistribution(uid: { eq: $uid }) {
      uid
      data {
        hero_image {
          alt
          copyright
          url
          gatsbyImageData
        }
        logo {
          alt
          copyright
          url
          gatsbyImageData
        }
        show_logo_on_production_page
        description {
          richText
        }
        title
        buttons {
          button_text
          button_link {
            type
            url
          }
        }
        homepage_link {
          type
          url
        }
        facebook_link {
          type
          url
        }
        instagram_link {
          type
          url
        }
        twitter_link {
          type
          url
        }
        youtube_link {
          type
          url
        }
        linkedin_link {
          type
          url
        }
        body {
          __typename
          ... on PrismicDistributionDataBodyInfobox {
            primary {
              heading
              subheading
            }
            items {
              icon {
                url
                alt
              }
              icon_description
              icon_heading
            }
          }
          ... on PrismicDistributionDataBodyOriginalSeriesList {
            items {
              series {
                document {
                  ... on PrismicSeries {
                    uid
                    data {
                      title
                      short_description
                      preview_image {
                        url
                        alt
                      }
                    }
                  }
                }
              }
            }
            primary {
              heading
              subheading
            }
          }
          ... on PrismicDistributionDataBodySeriesLogosList {
            items {
              series_link {
                url
              }
              series_logo {
                url
                alt
              }
            }
          }
          ... on PrismicDistributionDataBodyImagelist {
            primary {
              heading
            }
            items {
              title1
              image {
                url
                alt
              }
              link {
                url
              }
            }
          }
          ... on PrismicDistributionDataBodyDivisions {
            primary {
              divisions_heading
            }
            items {
              division_image {
                url
                alt
              }
              division_title
              division_description
              division_link {
                url
                uid
              }
            }
          }
          ... on PrismicDistributionDataBodyCarousel {
            items {
              button_link {
                url
              }
              button_text
              image {
                url
                alt
              }
              image_heading
            }
            primary {
              heading
            }
          }
        }
      }
    }
    prismicDistributionIndex {
      data {
        productions {
          production {
            document {
              ... on PrismicProduction {
                uid
                data {
                  logo {
                    url
                    alt
                  }
                  title
                  short_description
                }
              }
              ... on PrismicDistribution {
                uid
                data {
                  logo {
                    url
                    alt
                  }
                  title
                  short_description
                }
              }
            }
          }
        }
      }
    }
  }
`;
