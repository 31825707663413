import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as ReactCarousel } from "react-responsive-carousel";

import Container from "../styles/Container";

const Wrapper = styled.div`
  padding-top: 37px;
  padding-bottom: 37px;
  border-top: 1px solid ${(p) => p.theme.lightGrey};
  ${(p) => p.noTopBorder && "border-top: none;"}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  .carousel-slider {
    padding-bottom: 50px;
  }
  .control-dots {
    bottom: -16px !important;
  }
  .slide {
    background: white !important;
  }
  .dot {
    opacity: 1 !important;
    width: 11px !important;
    height: 11px !important;
    background-color: ${(p) => p.theme.lightMidGrey} !important;
    box-shadow: none !important;
    @media (min-width: ${(p) => p.theme.breakpointMd}) {
      width: 16px !important;
      height: 16px !important;
    }
    :hover {
      background-color: ${(p) => p.theme.midGrey} !important;
    }
  }
  .dot.selected {
    background-color: ${(p) => p.theme.midGrey} !important;
  }
`;

const Heading = styled.h1`
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 37px;
  text-align: center;
  ${(p) => p.lightBackground && `color: ${p.theme.greyishBlue};`}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 50px;
  }
`;

const SlideContainer = styled.div`
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  ${(p) =>
    p.squareOnMobile &&
    css`
      height: 100vw;
      object-fit: cover;
      @media (min-width: 320px) {
        height: 100%;
        min-height: 320px;
      }
    `}
`;

const ImageText = styled.div`
  position: absolute;
  left: 0%;
  right: 0%;
  bottom: 37px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    bottom: 50px;
  }
`;

const ImageHeading = styled.h1`
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: white;
  ${(p) => p.lightBackground && `color: ${p.theme.greyishBlue};`}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 50px;
    line-height: 60px;
  }
`;

const Button = styled.a`
  display: block;
  padding: 4px 60px;
  border: 4px solid white;
  border-radius: 4px;
  margin-top: 14px;
  color: white;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-top: 26px;
    font-size: 22px;
    line-height: 30px;
  }
`;

const Carousel = ({
  heading,
  items,
  noTopBorder,
  fullwidth,
  squareOnMobile,
  noPadding,
}) => {
  useEffect(() => {
    setTimeout(() => window.dispatchEvent(new Event("resize")), 100);
  });
  return (
    <Container fullwidth={fullwidth} noPadding={noPadding}>
      <Wrapper noTopBorder={noTopBorder}>
        {heading && <Heading>{heading}</Heading>}
        {items && (
          <CarouselContainer>
            <ReactCarousel
              showThumbs={false}
              showStatus={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={5000}
              transitionTime={500}
              swipeScrollTolerance={50}
            >
              {items.map((item, index) => {
                return (
                  <SlideContainer key={index}>
                    <Image
                      src={item.image.url}
                      alt={item.image.alt}
                      squareOnMobile={squareOnMobile}
                    />
                    <ImageText>
                      {item.image_heading && (
                        <ImageHeading>{item.image_heading}</ImageHeading>
                      )}
                      {item.button_text &&
                        item.button_link &&
                        item.button_link.url && (
                          <Button
                            href={item.button_link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.button_text}
                          </Button>
                        )}
                    </ImageText>
                  </SlideContainer>
                );
              })}
            </ReactCarousel>
          </CarouselContainer>
        )}
      </Wrapper>
    </Container>
  );
};

export default Carousel;
