import React from "react";
import styled from "styled-components";
import get from "lodash.get";

import Container from "../styles/Container";

const Wrapper = styled.div`
  padding-top: 37px;
  border-top: 1px solid ${(p) => p.theme.lightGrey};
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 50px;
  }
`;

const Heading = styled.h1`
  margin-bottom: 37px;
  font-size: 18px;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 50px;
    font-size: 30px;
    line-height: 42px;
  }
`;

const DivisionsContainer = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
  }
`;

const Division = styled.div`
  margin-bottom: 37px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 50px;
    width: calc((100% - 64px) / 3);
    :not(:last-child) {
      margin-right: 32px;
    }
  }
`;

const Image = styled.img`
  width: 100%;
  margin-bottom: 30px;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 900;
  text-align: center;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 24px;
    line-height: 30px;
  }
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 26px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 16px;
    line-height: 30px;
  }
`;

const LearnMore = styled.a`
  font-size: 14px;
  line-height: 26px;
  font-weight: 700;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 16px;
    line-height: 30px;
  }
  :hover {
    text-decoration: underline;
  }
`;

const Divisions = ({ heading, items, uid }) => {
  return (
    <Container>
      <Wrapper>
        {heading && <Heading>{heading}</Heading>}
        <DivisionsContainer>
          {items &&
            items.map((item, index) => {
              const isWebLink = item.division_link.uid === null;
              const link = isWebLink
                ? item.division_link.url
                : `/distribution/${uid}/${item.division_link.uid}`;

              return (
                <Division key={index}>
                  <a
                    href={link}
                    target={isWebLink ? "_blank" : undefined}
                    rel="noopener noreferrer"
                  >
                    <Image
                      src={item.division_image.url}
                      alt={item.division_title}
                    />
                  </a>
                  {item.division_title && <Title>{item.division_title}</Title>}
                  {item.division_description && (
                    <>
                      <Description>{item.division_description}</Description>
                      {link && (
                        <LearnMore
                          href={link}
                          target={isWebLink ? "_blank" : undefined}
                          rel="noopener noreferrer"
                        >
                          Learn More
                        </LearnMore>
                      )}
                    </>
                  )}
                </Division>
              );
            })}
        </DivisionsContainer>
      </Wrapper>
    </Container>
  );
};

export default Divisions;
